<template>
	<div ref="breadcrumbsRefs" class="breadcrumbs">
		<div
			v-for="breadcrumb in breadcrumbs"
			ref="breadcrumbsRef"
			:key="breadcrumb.key"
			class="breadcrumb"
			:class="{ 'breadcrumb--active': breadcrumb.key === siteStore.getCurrentNode?.key }"
			@click="getBreadcrumb(breadcrumb)">
			<div
				class="breadcrumb__icon-container"
				:class="{ 'breadcrumb__icon-container--active': breadcrumb.key === siteStore.getCurrentNode?.key }">
				<div v-if="breadcrumb.key === siteStore.getCurrentNode?.key" class="breadcrumb__icon-number">
					{{ breadcrumb.step }}
				</div>
				<v-icon v-else class="breadcrumb__icon-check" icon="mdi-check"></v-icon>
			</div>
			<div class="breadcrumb__name" :class="{ 'breadcrumb__name--active': breadcrumb.key === siteStore.getCurrentNode?.key }">
				{{ breadcrumb.label }}
			</div>

			<p v-if="breadcrumb.step !== lastBreadcrumb.step" class="breadcrumb__divider">—</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { INodeHistory } from '~/types/nodeTypes'

const { changeSiteStateBackwards } = useNavigationService()

const siteStore = useSiteStore()
const breadcrumbs = ref<INodeHistory[]>([])
const lastBreadcrumb = computed(() => breadcrumbs.value[breadcrumbs.value.length - 1])

const createBreadcrumbs = (history: INodeHistory[]) => {
	breadcrumbs.value = []
	for (let i = 0; i < history.length; i++) {
		const obj = {
			...history[i],
			step: i + 1,
		}
		breadcrumbs.value.push(obj)
	}
}

// createBreadcrumbs(siteStore.getHistory as INodeHistory[])

const getBreadcrumb = (breadcrumb: INodeHistory) => {
	changeSiteStateBackwards(breadcrumb.key, breadcrumb.step)
}

const breadcrumbsRef = ref()
const breadcrumbsRefs = ref()

watch(
	() => siteStore.getCurrentNode,
	async (newNode) => {
		await nextTick()
		if (newNode) {
			const breadcrumb = breadcrumbsRef.value ? breadcrumbsRef.value[breadcrumbsRef.value.length - 1] : null
			if (breadcrumb) {
				breadcrumbsRefs.value.scrollTo({
					left: breadcrumb.offsetLeft,
					behavior: 'smooth',
				})
			}
		}
	},
	{ immediate: true }
)

watch(
	() => siteStore.getHistory,
	(newHistory) => {
		if (newHistory) createBreadcrumbs(newHistory)
	},
	{ immediate: true, deep: true }
)
</script>

<style lang="scss">
@import './Breadcrumbs.scss';
</style>
