<template>
	<div class="global-navbar">
		<div v-if="currentState?.type === StepType.SUCCESS" class="global-navbar__placeholder"></div>
		<div
			class="global-navbar__logo-container"
			:class="{ 'global-navbar__logo-container--last': currentState?.type === StepType.SUCCESS }"
			@click="closeComplaint">
			<img src="@/assets/img/viking-logo.png" alt="Kuchnia Vikinga" class="global-navbar__logo" />
		</div>
		<Breadcrumbs v-if="currentState?.type !== StepType.SUCCESS" />
		<div class="global-navbar__close" @click="closeComplaint">
			<div class="global-navbar__icon-container">
				<v-icon class="global-navbar__icon" icon="mdi-close" />
			</div>
			<div class="global-navbar__text">Zamknij</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { INode } from '~/types/nodeTypes'
import { StepType } from '~/data/keys'

const siteStore = useSiteStore()
const currentState = ref<INode | null>()

watch(
	() => siteStore.getCurrentNode,
	(currentNode) => {
		currentState.value = currentNode
	},
	{ immediate: true }
)

const closeComplaint = () => {
	if (currentState.value?.type === StepType.SUCCESS) window.location.href = 'https://kuchniavikinga.pl/'
	else siteStore.setCloseModal(true)
}

watch(
	() => siteStore.getCurrentNode,
	(currentNode) => {
		currentState.value = currentNode
	},
	{ immediate: true }
)
</script>

<style lang="scss">
@import './Navbar.scss';
</style>
