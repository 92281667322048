<template>
	<div class="container-mobile">
		<div class="container-mobile__back" :class="!isVisible && 'container-mobile__back--hidden'" @click="getPreviousStep">
			<v-icon class="container-mobile__icon" icon="mdi-chevron-left"></v-icon> Cofnij
		</div>
		<div class="container-mobile__close" @click="closeComplaint">Zamknij</div>
	</div>
</template>

<script setup lang="ts">
import { INode } from 'types/nodeTypes'
const { getPreviousStep } = useNavigationService()
const siteStore = useSiteStore()
const currentState = ref<INode | null>()

watch(
	() => siteStore.getCurrentNode,
	(currentNode) => {
		currentState.value = currentNode
	},
	{ immediate: true }
)

const closeComplaint = () => {
	if (currentState.value?.type === StepType.SUCCESS) window.location.href = 'https://kuchniavikinga.pl/'
	else siteStore.setCloseModal(true)
}

const isVisible = computed(() => {
	return siteStore.getHistory && siteStore.getHistory.length > 1
})
</script>

<style lang="scss">
@import './MobileOptionsComponent.scss';
</style>
