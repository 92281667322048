<template>
	<div class="default-layout">
		<Transition name="modalFade">
			<GlobalCloseModal v-if="siteStore.getCloseModal" />
		</Transition>
		<GlobalNavbar />
		<GlobalMobileOptionsComponent></GlobalMobileOptionsComponent>
		<slot />
	</div>
</template>

<script setup lang="ts">
const siteStore = useSiteStore()

const router = useRouter()

watch(
	() => siteStore.getCloseModal,
	() => {
		if (siteStore.getCloseModal) {
			document.body.style.overflow = 'hidden'
			router.push({ hash: '#close-modal' })
		} else {
			document.body.style.overflow = 'auto'
			router.push({ hash: '' })
		}
	}
)

watch(
	() => router.currentRoute.value.hash,
	() => {
		if (router.currentRoute.value.hash === '#close-modal') {
			siteStore.setCloseModal(true)
		} else {
			siteStore.setCloseModal(false)
		}
	}
)
</script>

<style lang="scss">
@import './default.scss';
</style>
