<template>
	<div id="close-modal" class="close-modal">
		<div ref="closeModal" class="close-modal__container">
			<div class="close-modal__text">
				<div class="close-modal__title">Czy na pewno chcesz przerwać składanie reklamacji?</div>
				<div class="close-modal__desc">Wprowadzone przez Ciebie dane nie zostaną zapisane.</div>
			</div>
			<div class="close-modal__buttons">
				<ButtonPrimary class="close-modal__primary-button" :text="'Przerwij składanie reklamacji'" @click="startOver"></ButtonPrimary>
				<div class="close-modal__secondary-button" @click="siteStore.setCloseModal(false)">Kontynuuj składanie reklamacji</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const siteStore = useSiteStore()

const closeModal = ref(null)

onClickOutside(closeModal, () => {
	siteStore.setCloseModal(false)
})

const startOver = () => {
	siteStore.clearHistory()
	siteStore.setCurrentNode(availableNodes[0])
	siteStore.addHistory(availableNodes[0], '')
	siteStore.setCloseModal(false)
	window.location.href = 'https://kuchniavikinga.pl/'
}
</script>

<style lang="scss">
@import './CloseModal.scss';
</style>
